<template>
  <b-container id="users-list-module" fluid>
    <!-- Header -->
    <div class="d-flex pb-3 align-items-center justify-content-between">
      <h5 class="mb-0">Users List</h5>
      <input class="col-2 ml-auto mr-3 form-control" v-model="searchByUsername" type="search"
             placeholder="Search by username"
             aria-label="Search">
      <select class="col-2 form-control" v-model="role">
        <option value="">All roles</option>
        <option value="Manager">Manager</option>
        <option value="Production">Supply Chain</option>
        <option value="Admin">Admin</option>
        <option value="Finance">Finance</option>
        <option value="Licensing">Licensing</option>
        <option value="Care_Coordinator">Care Coordinator</option>
      </select>
    </div>
    <!-- All user listing -->
    <table class="table users-list-table mx-0 mb-2">
      <thead class="thead-light">
      <tr class="users-list-table__headings">
        <th scope="col">ID</th>
        <th scope="col">ACL UUID</th>
        <th scope="col">Username</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Roles</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr class="users-list-table__item"
          v-for="(user, index) in users"
          :key="index">
        <td class="align-middle">{{ user.id }}</td>
        <td class="align-middle">{{ user.acl_uuid ?? '-' }}</td>
        <td class="align-middle">{{ user.username }}</td>
        <td class="align-middle">{{ user.firstname }} {{ user.lastname }}</td>
        <td class="align-middle">{{ user.email }}</td>
        <td class="align-middle">{{ user.phone ? user.phone.number : '-' }}</td>
        <td class="align-middle">{{ user.role }}</td>
        <td class="align-middle users-list-table__item-actions">
          <b-button v-b-tooltip.hover
                    title="Edit"
                    size="sm"
                    variant="light"
                    class="users-list-table__item-actions-button"
                    :to="editUser(user)">
            <feather type="edit-2"></feather>
          </b-button>
          <b-button v-b-tooltip.hover
                    size="sm"
                    title="Delete"
                    variant="light"
                    class="users-list-table__item-actions-button"
                    @click="tryUserDelete(user)">
            <feather type="trash"></feather>
          </b-button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Pagination -->
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="pagination.currentPage"
                      :total-rows="pagination.totalRows"
                      :per-page="pagination.itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios';

// Mixins
import deleteUserActions from '@/mixins/lists/delete-user-action';
import pagination from '@/mixins/lists/pagination';

export default {
  name: 'UsersList',
  mixins: [ pagination, deleteUserActions ],
  data() {
    return {
      users: [],
      searchByUsername: '',
      loadingPage: false,
      role: '',
      searchTimeout: null,
    };
  },
  mounted() {
    this.requestUsers();
  },
  methods: {
    tryUserDelete(user) {
      this.safeDeleteUser(user).then(() => this.requestUsers());
    },
    onPageChange() {
      this.requestUsers();
    },
    requestUsers() {
      this.loadingPage = true;
      const page = this.pagination.currentPage - 1;
      const query = [
        `limit=${this.pagination.itemsPerPage}`, `offset=${page * this.pagination.itemsPerPage}`, 'excludeRoles=Professional',
      ];
      if (this.searchByUsername) {
        query.push(`search=${this.searchByUsername}`);
      }
      if (this.role) {
        query.push(`role=${this.role}`);
      }
      axios.get(`v1/users?${query.join('&')}`, { params: { client: 'back-office' } }).then(response => {
        this.users = response.data.users;
        this.pagination.totalRows = response.data.total;
      }).catch(err => {
        this.$noty.error('Failed to fetch users: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    editUser(user) {
      // Filter trough all the user roles
      const professionalRoles = user.roles.filter(role => [ 'manager', 'professional' ].includes(role.name.toLowerCase()));

      if (professionalRoles.length > 0) {
        return `/professionals/${user.id}/edit-deprecated`;
      }

      return `/users/edit/${user.id}`;
    },
  },
  watch: {
    searchByUsername() {
      clearTimeout(this.searchTimeout);
      setTimeout(() => {
        this.requestUsers();
      }, 400);
    },
    role() {
      this.requestUsers();
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/_user-tables";
</style>
